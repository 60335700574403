<template>
  <div
    class="login-page-fix"
    style="background-color: white"
    :style="`${IS_DOH_THEME ? `height: auto` : ''}`"
  >
    <LoadingOverlay v-if="isLoginFetching" />
    <CRow class="m-0" v-show="elementVisible">
      <CCol class="p-0" id="bg-img" style="width: 50%">
        <img
          v-if="!isAcronymBasedLogin"
          :src="getBanner"
          alt="bg"
          style="width: 100%; margin-top: 5px; height: 650px"
        />
        <img
          v-else
          :src="getBanner"
          style="width: 100%; margin-top: 5px; height: 650px"
          alt="bg"
        />
      </CCol>
      <CCol class="p-0">
        <CContainer
          class="login-container"
          style="min-height: calc(100vh - 95px)"
        >
          <CCardGroup
            class="d-flex card-group has-loading-overlay justify-content-center"
          >
            <CCard class="sign-in-card p-0 border-0">
              <CCardBody>
                <CForm @submit.prevent="loginSubmit">
                  <img
                    class="loginLogo"
                    v-if="IS_DOH_THEME"
                    style="width: 100%"
                    :src="getCustomerLogo"
                    @error="$event.target.src = '/img/doh.png'"
                    alt="logo"
                  />
                  <img
                    class="loginLogo"
                    v-else
                    style="width: 100%"
                    :src="getHeaderLogo"
                    @error="$event.target.src = '/img/talentfind_logo2.png'"
                    alt="logo"
                  />
                  <div class="title mt-4 text-center">
                    <strong>Sign in to </strong>
                    <strong v-if="IS_DOH_THEME">your account</strong>
                    <img
                      v-if="isAcronymBasedLogin && !IS_DOH_THEME"
                      :src="getCustomerLogo"
                      style="margin-bottom: 3px; width: 130px; height: 18px"
                      @error="
                        $event.target.outerHTML = `<span style='color: #dc0050'><strong>TALENT</strong>FIND<strong style='vertical-align: super; font-size: 13px'>TM</strong></span>`
                      "
                      alt="logo"
                    />
                    <span
                      v-if="!isAcronymBasedLogin && !IS_DOH_THEME"
                      style="color: #dc0050"
                    >
                      <strong>TALENT</strong>FIND<strong
                        style="vertical-align: super; font-size: 13px"
                        >TM</strong
                      >
                    </span>
                  </div>
                  <CRow class="mt-3">
                    <label class="ml-3">Email Address</label>
                    <CCol class="text-right">
                      <span
                        class="login-hint text-primary"
                        v-c-tooltip="'Your email is your username!'"
                        >Login Hint</span
                      >
                    </CCol>
                  </CRow>
                  <CInput
                    name="login-email"
                    autocomplete="username email"
                    v-model="username"
                    tabindex="1"
                  ></CInput>
                  <CRow>
                    <label class="ml-3 pt-2">Password</label>
                    <CCol class="text-right">
                      <CButton
                        @click="redirectToForgot"
                        color="link"
                        class="px-0"
                        >Forgot password?</CButton
                      >
                    </CCol>
                  </CRow>
                  <CInput
                    name="login-password"
                    type="password"
                    v-model="password"
                    autocomplete="curent-password"
                    tabindex="2"
                  ></CInput>
                  <CRow class="justify-content-end mb-4">
                    <CButton
                      name="login-submit"
                      :disabled="isLoginFetching"
                      type="submit"
                      class="px-4 mr-3 btn-primary"
                      >{{
                        !isLoginFetching ? "Sign in" : "Loading..."
                      }}</CButton
                    >
                  </CRow>
                  <div
                    v-if="isAcronymBasedLogin"
                    class="d-flex justify-content-center"
                  >
                    <p style="font-size: 10px" class="mb-0 mr-3">
                      Click here to
                      <CButton
                        @click="redirectToUnsubsribe"
                        color="link"
                        class="px-0"
                        style="font-size: 10px"
                        >unsubscribe</CButton
                      >
                    </p>
                  </div>
                  <div
                    class="d-flex justify-content-center"
                    v-if="isAcronymBasedLogin"
                  >
                    <CButton
                      name="register-now"
                      color="link"
                      class="font-weight-bold active px-0 fs-15 text-primary"
                      @click="redirectToPublicJobBoard"
                      >View & Apply Jobs<span class="small-text ml-1"
                        ><sup>New</sup></span
                      ></CButton
                    >
                  </div>
                  <div
                    class="d-flex justify-content-center mt-4 pt-4"
                    v-if="isAcronymBasedLogin"
                  >
                    <router-link to="/check-facility" class="btn btn-primary">
                      Facility Registration<span class="small-text ml-1"
                        ><sup>New</sup></span
                      >
                    </router-link>
                  </div>

                  <div
                    class="d-flex justify-content-center mt-4 pt-4"
                    v-if="!isAcronymBasedLogin"
                  >
                    <router-link
                      to="/dohae"
                      class="btn btn-primary"
                      name="link_acronym_login"
                    >
                      Login as Facility/Candidate<span
                        class="small-text ml-1"
                      ></span>
                    </router-link>
                  </div>
                </CForm>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CContainer>
      </CCol>
    </CRow>
    <SwitchOrgModal
      v-if="switchOrgModal.isShowPopup"
      :isShowPopup="switchOrgModal.isShowPopup"
      @modalCallBack="switchOrgModalCallBack"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import {
  Role,
  CANDIDATE_URL,
  FACILITY_CANDIDATE_URL,
  LOGIN_URL,
  IS_DOH_THEME,
  getLocalOrgDetail,
} from "@/helpers/helper";
import SwitchOrgModal from "@/views/pages/SwitchOrgModal";

export default {
  name: "Login",
  components: {
    SwitchOrgModal,
  },
  data() {
    return {
      Role,
      CANDIDATE_URL,
      FACILITY_CANDIDATE_URL,
      IS_DOH_THEME,
      getLocalOrgDetail,
      username: "",
      password: "",
      elementVisible: false,
      switchOrgModal: {
        isShowPopup: false,
        organisations: [],
      },
    };
  },
  created() {
    setTimeout(() => (this.elementVisible = true), 350);
  },
  computed: {
    ...mapGetters([
      "getUserData",
      "isLoginFetching",
      "getRoleScopes",
      "getImages",
      "isAcronymBasedLogin",
      "accessibleOrgs",
      "getRole",
    ]),
    getBanner() {
      return this.getImages.banner;
    },
    getCustomerLogo() {
      return this.getImages.customer_logo;
    },
    getHeaderLogo() {
      return this.getImages.header_logo;
    },
    getScreenSize() {
      return {
        width: screen.availWidth,
        height: screen.availHeight,
      };
    },
  },
  mounted() {
    if (this.getRoleScopes.length) {
      if (
        this.getRoleScopes[0] === Role.systemCandidate ||
        this.getRoleScopes[0] === Role.customerCandidate
      ) {
        this.$router.push("/home");
      } else if (this.getRoleScopes[0] === Role.systemAdmin) {
        this.$router.push(this.CANDIDATE_URL);
      } else {
        this.$router.push(this.FACILITY_CANDIDATE_URL);
      }
    }
  },
  methods: {
    ...mapActions([
      "login",
      "showToast",
      "resetCandidate",
      "isEmailBelongsToAcronym",
      "fetchOrganisationAccessToken",
    ]),
    loginSubmit() {
      if (this.username && this.password) {
        this.isEmailBelongsToAcronym(this.username.toLowerCase()).then(
          (res) => {
            if (res.data) {
              this.login({
                username: this.username,
                password: this.password,
              }).then((res) => {
                if (
                  this.accessibleOrgs?.length > 1 &&
                  this.getRole === Role.customerRecruiter
                ) {
                  this.switchOrgModal.isShowPopup = true;
                  this.switchOrgModal.organisations = this.accessibleOrgs;
                }
              });
            }
          }
        );
      } else {
        this.showToast({
          class: "bg-danger text-white",
          message: "Please fill the credentials",
        });
      }
    },
    redirectToForgot() {
      this.$router.push({
        path: `${
          this.isAcronymBasedLogin ? `${LOGIN_URL()}/forgot` : "/forgot"
        }`,
      });
    },
    redirectToUnsubsribe() {
      this.$router.push({ path: LOGIN_URL() + "/unsubscribe" });
    },
    redirectToPublicJobBoard() {
      this.$router.push({
        path: `/public/job-board/${getLocalOrgDetail()?.customer_uid}`,
      });
    },
    switchOrgModalCallBack(action, data) {
      if (action && data) {
        this.fetchOrganisationAccessToken(data).then((res) => {
          if (res?.status === 200) {
            this.switchOrgModal.isShowPopup = false;
            this.$router.push({ path: "/recruiter-dashboard" });
          }
        });
      }
      this.switchOrgModal.isShowPopup = false;
    },
  },
};
</script>
 
<style lang="scss" scoped>
@media (max-width: 1000px) {
  #bg-img {
    display: none;
  }
}
.login-container {
  .card-group {
    .sign-in-card {
      max-width: 400px;
      .title {
        font-size: 22px;
        line-height: 28px;
      }
    }
  }
}
.login-hint {
  font-size: 0.65rem;
  color: #dd3651;
  font-weight: 400;
  cursor: pointer;
}
.login-page-fix {
  margin-top: -22px;
  margin-right: -30px;
  margin-left: -30px;
}
@media (max-width: 480px) {
  .title {
    padding-top: 3rem;
  }
}
.min-w-188 {
  min-width: 188px !important;
  text-align: center;
}
</style>
