<template>
  <div>
    <CModal :show.sync="isShowPopup" :close-on-backdrop="false" color="primary">
      <template #header>
        <h6 class="modal-title">Choose Facility</h6>
      </template>

      <template #footer>
        <CButton type="button" color="primary" class="px-4" @click="onSubmit"
          >Switch</CButton
        >
      </template>
      <div>
        <ValidationObserver ref="swicthOrganisation" v-slot="{ handleSubmit }">
          <form
            id="swicthOrganisation"
            @submit.prevent="handleSubmit(onSubmit)"
          >
            <CRow>
              <CCol md="4">
                <label>Select Facility</label>
              </CCol>
              <CCol md="8">
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <Select
                    name="organisation_id"
                    :value="uploadData.organisation_id"
                    :options="organisations"
                    @change="handleChangeSelect"
                    :clearable="false"
                    :error="errors[0]"
                  />
                </ValidationProvider>
              </CCol>
            </CRow>
          </form>
        </ValidationObserver>
      </div>
    </CModal>
  </div>
</template>
<script>
import Vue from "vue";
import Select from "@/components/reusable/Fields/Select";
import { mapGetters } from "vuex";
export default {
  name: "switchOrgModal",
  props: {
    isShowPopup: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Select,
  },
  data() {
    return {
      uploadData: {},
    };
  },
  computed: {
    ...mapGetters(["accessibleOrgs", "getOrgIDFromAccessToken"]),
    organisations() {
      return (
        this.accessibleOrgs?.map((v) => ({
          code: v?.organisation,
          label: v?.name,
        })) || []
      );
    },
  },
  methods: {
    handleChangeSelect(name, value) {
      Vue.set(this.uploadData, name, value);
    },
    async onSubmit() {
      const isValid = await this.$refs.swicthOrganisation.validate();
      if (!isValid) {
        this.showToast({
          class: "bg-danger text-white",
          message: "please select facility!",
        });
        return;
      }
      this.modalCallBack(true, this.uploadData?.organisation_id?.code);
    },
    modalCallBack(action, data) {
      this.$emit("modalCallBack", action, data);
    },
  },
  mounted() {
    const org = this.organisations.find(
      (v) => v?.code === this.getOrgIDFromAccessToken
    );
    if (org) this.handleChangeSelect("organisation_id", org);
  },
};
</script>